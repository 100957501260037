<template>
  <QuotaAddDocument />
</template>

<script>
export default {
  name: "QuotaAddDocumentPage",
  components: {
    QuotaAddDocument: () => import("~cp/Quota/QuotaPreview/QuotaAddDocument"),
  },
};
</script>

<style>
</style>